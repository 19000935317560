import CartApi from "@yoco/shop-sdk/lib/api/CartApi"
import ProductApi from "@yoco/shop-sdk/lib/api/ProductApi"
import HttpClient from "@yoco/shop-sdk/lib/HttpClient"

import { getUrlFromStoryblokLink } from "./storyblok"

const http = new HttpClient(`${process.env.GATSBY_SHOP_API_URL}`)
export const shop = {
  carts: new CartApi(http),
  products: new ProductApi(http),
}

const isBrowser = typeof window !== "undefined"

export const localStorageAvailable = (): boolean => {
  if (!isBrowser) {
    return false
  }
  try {
    localStorage.setItem("testCookie", "testCookie")
    localStorage.removeItem("testCookie")
    return true
  } catch (e) {
    return false
  }
}

export const fetchCartId = (): string | null => {
  if (localStorageAvailable()) {
    return localStorage.getItem("cartId")
  }
  return null
}

export const storeCartId = (id: string): void => {
  if (localStorageAvailable()) {
    localStorage.setItem("cartId", id)
  }
}

export const productAlreadyInCart = (sku: string): boolean => {
  const products = fetchCartProducts()

  const inCart = products?.find((product) => {
    // This is a hotfix for users who already have existing cart items without IDs
    if (!product.id) {
      clearCart()
      return false
    }

    return product.sku === sku
  })

  return inCart ? true : false
}

// TODO consolidate this with productAlreadyInCart method
export const productAlreadyInCartInBundle = (sku: string): boolean => {
  const products = fetchCartProducts()

  const inCart = products?.find((product) => {
    // This is a hotfix for users who already have existing cart items without IDs
    if (!product.id) {
      clearCart()
      return false
    }

    return product.sku === sku && product.bundleId !== undefined
  })

  return inCart ? true : false
}

// TODO dont think can be consolidated. but revisit once refactoring products
export const bundleAlreadyInCart = (bundleId: string): boolean => {
  const products = fetchCartProducts()

  const inCart = products?.find((product) => {
    // This is a hotfix for users who already have existing cart items without IDs
    if (!product.id) {
      clearCart()
      return false
    }

    return product.bundleId === bundleId
  })

  return inCart ? true : false
}

export interface StoredProduct {
  id: string
  sku: string
  quantity: number
  bundleId?: string
  bundleName?: string
  isBundleParent?: boolean
}

export const fetchCartProducts = (): StoredProduct[] | null => {
  if (localStorageAvailable()) {
    const products = localStorage.getItem("cartProducts")
    return products ? JSON.parse(products) : []
  }

  return null
}

export const getProductName = (
  sdkProduct: YocoCom.SdkProduct | null,
  storyblokName: string
): string => {
  return sdkProduct?.short_name || storyblokName
}

export interface ProductPrice {
  price: string
  originalPrice?: string
}

export const getProductPrices = (
  product: YocoCom.SdkProduct | null,
  storyblokPrice: string,
  forceStoryblokPrice: boolean,
  storyblokPromotionPrice?: string
): ProductPrice => {
  if (forceStoryblokPrice || !product) {
    const storyblokPrices: ProductPrice = {
      price: storyblokPromotionPrice ? storyblokPromotionPrice : storyblokPrice,
    }
    if (storyblokPromotionPrice && parseFloat(storyblokPrice) > 0) {
      storyblokPrices["originalPrice"] = storyblokPrice
    }

    return storyblokPrices
  }

  const sdkPrices: ProductPrice = {
    price: product.unit_price,
  }

  if (parseFloat(product.original_unit_price) > 0) {
    sdkPrices["originalPrice"] = product.original_unit_price
  }

  return sdkPrices
}

export const consolidateProduct = (
  productId: string,
  quantity: number,
  sdkProduct: YocoCom.SdkProduct,
  storyblokProduct: Storyblok.ProductConfig,
  bundleId?: string,
  bundleName?: string,
  isBundleParent?: boolean
): YocoCom.ConsolidatedProduct => {
  const productPrices = getProductPrices(
    sdkProduct,
    storyblokProduct.price,
    storyblokProduct.forceStoryblokPrice,
    storyblokProduct.promotionPrice
  )

  return {
    id: productId,
    sku: storyblokProduct.sku ?? sdkProduct.sku,
    name: sdkProduct.short_name ?? storyblokProduct.name,
    image: storyblokProduct?.image ?? sdkProduct.image,
    quantity: quantity,
    maxQuantity: sdkProduct.max_quantity_per_cart,
    price: productPrices.price,
    originalPrice: productPrices.originalPrice,
    detailLink: getUrlFromStoryblokLink(storyblokProduct.detailsLink),
    description: storyblokProduct.fullDescription ?? sdkProduct.description,
    galleryItems: storyblokProduct.galleryItems,
    thirdPartyReviews: sdkProduct.third_party_reviews,
    recommendedProducts: storyblokProduct.recommendedProducts,
    recommendedLabel: storyblokProduct.recommendedLabel,
    showRecommendedProducts: storyblokProduct.showRecommendedProducts,
    promotionStartDate: storyblokProduct.promotionStartDate,
    promotionEndDate: storyblokProduct.promotionEndDate,
    reasonNotAvailable: sdkProduct.reason_not_available,
    bundleId: bundleId,
    bundleName: bundleName,
    isBundleParent: isBundleParent,
  }
}

export const storeCartProducts = (products: StoredProduct[]): void => {
  if (localStorageAvailable()) {
    localStorage.setItem("cartProducts", JSON.stringify(products))
  }
}

export const updateStoredProductQuantity = (
  sku: string,
  quantity: number
): StoredProduct[] | null => {
  const products = fetchCartProducts()

  const target = products?.find((product) => {
    return product.sku === sku
  })

  if (target) {
    target.quantity = quantity
    return products
  }

  return null
}

export const fetchCartPromoCode = (): string | null => {
  if (localStorageAvailable()) {
    return localStorage.getItem("promoCode")
  }
  return null
}

export const storeCartPromoCode = (promoCode: string): void => {
  if (localStorageAvailable()) {
    localStorage.setItem("promoCode", promoCode)
  }
}

export const clearCartPromoCode = (): void => {
  if (localStorageAvailable()) {
    localStorage.removeItem("promoCode")
  }
}

export const clearCartId = (): void => {
  if (localStorageAvailable()) {
    localStorage.removeItem("cartId")
  }
}

export const clearCart = (): void => {
  if (localStorageAvailable()) {
    localStorage.removeItem("cartId")
    localStorage.removeItem("cartProducts")
    localStorage.removeItem("promoCode")
  }
}

export const humanizeAddressText = (
  address: YocoCom.ShippingAddress
): string => {
  const addressList = []

  if (address.country) {
    addressList.push(address.country)
  }
  if (address.street) {
    addressList.push(address.street)
  }
  if (address.suburb) {
    addressList.push(address.suburb)
  }
  if (address.city) {
    addressList.push(address.city)
  }
  if (address.province) {
    addressList.push(address.province)
  }
  if (address.postalCode) {
    addressList.push(address.postalCode)
  }

  return addressList.join(", ")
}

export const isShopEnabled = (): boolean => {
  // This fetches a static setting determined at build time

  if (process.env.GATSBY_IS_SHOP_ENABLED === "true") {
    return true
  } else {
    return false
  }
}
